import Form, { formCreate } from '@/found/components/form';

import request from '@/found/utils/request';
import ProductRespVos from './product_info.vue';
import PhotoRespVos from './photo_list.vue';

formCreate.component('ProductRespVos', ProductRespVos);
formCreate.component('PhotoRespVos', PhotoRespVos);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'customer_fee_form',
    };
  },
  created() {

  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'view') {
        this.setValue(this.formConfig.row);
      }
    },
    // 校验图片
    checkPhoto(pics) {
      request.post('/sfa/sensegalaxyfilestatus/queryFileStatus', pics).then((res) => {
        console.log(res);
        if (res.success) {
          this.fApi.getRule('photoRespVos').props.checkPicList = res.result || [];
        }
      });
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productRespVos') {
        v.props.value = this.formConfig.row.productRespVos || [];
      }
      if (v.field === 'photoRespVos') { // 选择预算科目
        const photoList = this.formConfig.row.photoRespVos || [];
        this.checkPhoto(photoList.map((pics) => pics.fileUrl));
        v.props.value = photoList;
        v.props.checkPicList = [];
      }
      return v;
    },
  },
};
